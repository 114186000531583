<template>
  <div class="centered-spinner">
    <spinner size="huge" message="Loading..."></spinner>
  </div>
</template>

<script>
/* eslint-disable */
import { VUE_APP_FLOW_NAME, VUE_APP_SUPPORT_EMAIL } from '@/utils/constants'
import { mapGetters } from 'vuex';
import axios from 'axios';
import authHeader from '../../utils/auth-header';
import Spinner from 'vue-simple-spinner';

export default {
  name: 'Verify',
  data() {
    return {};
  },
  components: {
    Spinner,
  },
  methods: {
    async VerifyIdentity() {
      this.loading = true;
      const apiUrl = process.env.VUE_APP_SUMSUB_URL
      const supportEmail = VUE_APP_SUPPORT_EMAIL
      const flowName = VUE_APP_FLOW_NAME // or set up your own with the dashboard
      const accessToken = await this.createAccessToken();
      const snsMobileSDK = SNSMobileSDK.Builder(apiUrl, flowName)
        .withAccessToken(accessToken, () => {
          // this is a token expiration handler, will be called if the provided token is invalid or got expired
          // call your backend to fetch a new access token (this is just an example)
          return new Promise(resolve => {
            resolve('new_access_token');
          });
        })
        .withHandlers({
          // Optional callbacks you can use to get notified of the corresponding events
          onStatusChanged: event => {
          },
        })
        .withDebug(true)
        .withSupportEmail(supportEmail)
        .withLocale('en') // Optional, for cases when you need to override system locale
        .build();
      snsMobileSDK
        .launch()
        .then(result => {
          this.loading = false;
          this.$router.push(`/`);
        })
        .catch(err => {
          this.loading = false;
          console.error(`SumSub SDK Error: ${JSON.stringify(err)}`); // TODO: show error to the user
        });
    },

    async createAccessToken() {
      const config = {};
      config.baseURL = process.env.VUE_APP_API_BASE_URL;
      const method = 'get';
      const url = `/v1/api/auth/kycToken`;
      const headers = authHeader();

      config.method = method;
      config.url = url;
      config.headers = headers;
      config.data = null;
      const response = await axios(config);
      return response.data.kycToken;
    },
  },
  computed: {
    ...mapGetters({ orderType: 'getOrderType' }),
  },
  mounted: async function() {
    await this.VerifyIdentity();
  },
};
</script>

<style scoped>
.centered-spinner {
  position: fixed;
  top: 40%;
  left: 50%;
  margin-top: -40px;
  margin-left: -100px;
}
</style>
